'use client';

import 'pure-react-carousel/dist/react-carousel.es.css';
import './customCss.css';

import { Children, ReactNode, useEffect, useState } from 'react';
import { SvgAngle } from '@icons/designSystemIcons/SvgAngle';
import { SvgArrow } from '@icons/designSystemIcons/SvgArrow';
import { SvgHolaGlowStar2 } from '@icons/designSystemIcons/SvgHolaGlowStar2';
import CheckHydration from '@utils/CheckHydration';
import { Button } from 'designSystem/Buttons/Buttons';
import { Container, Flex } from 'designSystem/Layouts/Layouts';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { twMerge } from 'tailwind-merge';

export default function Carousel({
  children,
  hasDots = false,
  hasCounter = false,
  hasControls = false,
  hasTopControls = false,
  isIntrinsicHeight = true,
  naturalSlideHeight = 100,
  naturalSlideWidth = 100,
  visibleSlides = 1,
  currentSlide = 0,
  dragEnabled = true,
  touchEnabled = true,
  className = '',
  sliderStyles = '',
  isFullWidth = false,
  isPlaying = false,
  isDashboard = false,
  isTruehair = false,
  linedDots = false,
  ...rest
}: {
  children: ReactNode;
  hasDots?: boolean;
  hasCounter?: boolean;
  hasControls?: boolean;
  hasTopControls?: boolean;
  isIntrinsicHeight?: boolean;
  naturalSlideHeight?: number;
  naturalSlideWidth?: number;
  visibleSlides?: number;
  step?: number;
  currentSlide?: number;
  dragEnabled?: boolean;
  touchEnabled?: boolean;
  className?: string;
  sliderStyles?: string;
  isFullWidth?: boolean;
  isPlaying?: boolean;
  isDashboard?: boolean;
  isTruehair?: boolean;
  linedDots?: boolean;
  [key: string]: any;
}) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const childrens = Children.toArray(children);

  const handleBackButton = () => {
    currentSlideIndex === 0
      ? setCurrentSlideIndex(childrens.length - 1)
      : setCurrentSlideIndex(currentSlideIndex - 1);
  };

  const handleNextButton = () => {
    currentSlideIndex === childrens.length - 1
      ? setCurrentSlideIndex(0)
      : setCurrentSlideIndex(currentSlideIndex + 1);
  };

  useEffect(() => {
    if (isFullWidth) {
      setCurrentSlideIndex(0);
    }
  }, [children]);

  const buttonBack = ({ isTopControl = false }: { isTopControl?: boolean }) => (
    <ButtonBack
      onClick={() => {
        handleBackButton();
      }}
      className="disabled:opacity-10 disabled:cursor-default"
    >
      <Button
        type={isTruehair ? 'white' : 'secondary'}
        customStyles={`px-0 aspect-square ${isTopControl ? 'bg-white' : ''} ${isTruehair ? 'bg-transparent hover:bg-transparent' : ''}`}
      >
        {isTopControl ? (
          <SvgAngle
            height={16}
            width={16}
            className={`rotate-180 ${
              isTruehair ? 'text-hg-black' : 'text-hg-secondary'
            }`}
          />
        ) : (
          <SvgArrow height={16} width={16} className="rotate-180" />
        )}
      </Button>
    </ButtonBack>
  );

  const buttonNext = ({ isTopControl = false }: { isTopControl?: boolean }) => (
    <ButtonNext
      onClick={() => {
        handleNextButton();
      }}
      className="disabled:opacity-10 disabled:cursor-default"
    >
      <Button
        type={isTruehair ? 'white' : 'secondary'}
        customStyles={`px-0 aspect-square ${isTopControl ? 'bg-white' : ''} ${isTruehair ? 'bg-transparent hover:bg-transparent' : ''}`}
      >
        {isTopControl ? (
          <SvgAngle
            height={16}
            width={16}
            className={isTruehair ? 'text-th-black' : 'text-hg-secondary'}
          />
        ) : (
          <SvgArrow height={16} width={16} />
        )}
      </Button>
    </ButtonNext>
  );

  return (
    <CheckHydration>
      <CarouselProvider
        className={twMerge(`relative w-full ${className}`)}
        isIntrinsicHeight={isIntrinsicHeight}
        totalSlides={childrens.length}
        currentSlide={isFullWidth || hasDots ? currentSlideIndex : currentSlide}
        lockOnWindowScroll={true}
        dragEnabled={dragEnabled}
        touchEnabled={touchEnabled}
        naturalSlideHeight={naturalSlideHeight}
        naturalSlideWidth={naturalSlideWidth}
        visibleSlides={visibleSlides}
        orientation="horizontal"
        isPlaying={isPlaying}
        interval={2000}
        {...rest}
      >
        <div className="relative">
          <Slider
            classNameTray={sliderStyles}
            verticalPixelThreshold={1000}
            preventVerticalScrollOnTouch={true}
            classNameAnimation="customCarouselAnimation"
          >
            {childrens.map((children, i) => (
              <Slide index={i} key={i}>
                {children}
              </Slide>
            ))}
          </Slider>
          {hasControls && isDashboard && (
            <div className="absolute inset-0 flex items-center justify-between ">
              {buttonBack({ isTopControl: hasTopControls })}
              {buttonNext({ isTopControl: hasTopControls })}
            </div>
          )}
        </div>

        {hasCounter && !isDashboard && (
          <Flex layout="row-center" className="mt-8 relative">
            <ul className="p-2 spacing flex gap-2 text-xs absolute">
              <li>{currentSlideIndex + 1}</li>
              <li>/</li>
              <li>{childrens.length}</li>
            </ul>
          </Flex>
        )}

        {hasDots && !linedDots && !isDashboard && childrens.length < 9 && (
          <Flex
            layout="row-center"
            className={`relative mt-8 ${rest.controlstyles ? rest.controlstyles : ''}`}
          >
            <DotGroup className="p-2 spacing flex text-xs absolute items-center">
              {childrens.map((dot, index) => {
                return (
                  <Dot key={index} slide={index}>
                    <SvgHolaGlowStar2 className="h-6 w-6 mx-1.5" />
                  </Dot>
                );
              })}
            </DotGroup>
          </Flex>
        )}

        {hasDots && linedDots && (
          <Flex
            layout="row-center"
            className={`relative mt-8 ${rest.controlstyles ? rest.controlstyles : ''}`}
          >
            <DotGroup className="-mt-8">
              {childrens.map((dot, index) => {
                return (
                  <Dot key={index} slide={index} className="linedDot">
                    <div className="h-1 w-8 bg-hg-black/5 rounded-full mx-2" />
                  </Dot>
                );
              })}
            </DotGroup>
          </Flex>
        )}

        {hasControls && !isDashboard && (
          <Container
            className={`${isFullWidth ? '' : 'px-0'} ${
              hasTopControls
                ? 'absolute top-[50%] -translate-y-[50%] px-4'
                : hasCounter
                  ? '-mt-4 '
                  : 'mt-8'
            } ${rest.controlstyles ? rest.controlstyles : ''}`}
          >
            <Flex
              layout={hasTopControls ? 'row-between' : 'row-right'}
              className="gap-6"
            >
              {buttonBack({ isTopControl: hasTopControls })}
              {buttonNext({ isTopControl: hasTopControls })}
            </Flex>
          </Container>
        )}
      </CarouselProvider>
    </CheckHydration>
  );
}
